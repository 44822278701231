import { useRequest } from 'ahooks'
import React, { useEffect, useState } from 'react'
import AppContext, { getInitRouterData } from './AppContextProvider'
import RootComponent from './RootComponent'
import { getBottomNavigation } from './api/common'
import { getUserLogin } from './api/user'
import { useGlobalConfig } from './hooks/useGlobalConfig'
import useStateCallback from './hooks/useStateCallback'
import { useUserInfo } from './hooks/useUserInfo'
import { ROUTES } from '@/constants/routers'
import {
  CommonConfigRes,
  InitRouterDataRes,
  LayoutDataProps
} from './interfaces/common'
import {
  getBaseReq,
  onTokenExpired,
  saveCredentials,
  saveToken,
  saveUID,
  saveUserInfo
} from './utils/session'
import { readUUID } from './utils/uuid'
import { useNavigate } from 'react-router-dom'
import {
  getChannelCode,
  getNativePlatform,
  getStatusBarHeight
} from './utils/native'

const App: React.FC = () => {
  const router = useNavigate()
  const { mutate } = useUserInfo({ manual: true })
  const [codeShellScripts, setCodeShellScripts] = useState<any[]>([])
  const [appData, setAppData] = useStateCallback<LayoutDataProps>(null as any)
  const [initRouterData, setInitRouterData] =
    useStateCallback<InitRouterDataRes>(null as any)
  const [importedPopComponent, setImportedPopComponent] = useState<any>(null)
  const dev = process.env.NODE_ENV !== 'production'
  const codeShellLoad = (str: string) => {
    if (str.startsWith('http') && str.includes('.js')) {
      if (codeShellScripts.includes(str)) return
      const strArr: any[] = []
      strArr.push(str)
      setCodeShellScripts(strArr)
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = str
      document.head.appendChild(script)
      return
    }
    const dataStr = str.includes('<script>')
      ? str.split('</script>')[0].replace('<script>', '')
      : str
    if (!dataStr) return
    // 添加过就不再添加
    if (codeShellScripts.includes(dataStr)) return
    const strArr: any[] = []
    strArr.push(dataStr)
    setCodeShellScripts(strArr)
    const script = document.createElement('script')
    script.innerHTML = dataStr
    document.head.appendChild(script)
  }
  // 获取全局配置
  useGlobalConfig({
    manual: false,
    onSuccess(data: CommonConfigRes) {
      if (data.umeng_config.length > 0) {
        data.umeng_config.forEach((item) => {
          codeShellLoad(item.js)
        })
      }
      setAppData((prev) => ({ ...prev, globalConfig: data }))
      // 动态导入弹出框
      const importComponent = async () => {
        const module = await import('./components/launchContent')
        const AnotherComponent = module.default
        setImportedPopComponent(<AnotherComponent globalConfig={data} />)
      }
      importComponent()
    }
  })

  // 获取底部导航
  useRequest(getBottomNavigation, {
    cacheKey: 'getBottomNavigation',
    manual: false,
    onSuccess(data) {
      setAppData((prev) => ({ ...prev, bottomNavData: data }))
    }
  })

  // 游客登录
  const { runAsync } = useRequest(getUserLogin, {
    manual: true
  })

  useEffect(() => {
    // 需要先调用游客登录接口
    const params = getBaseReq()
    // 如果没有token 和uid 表示未登录需要执行游客登录
    // IOS 壳子有异常，需要每次进入都调用游客登录
    if (getNativePlatform() === 'ios' || !params.token || !params.uid) {
      const requestUrl = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(requestUrl.search)
      const userInvite = urlSearchParams.get('user_invite') || ''
      runAsync({
        devid: readUUID(),
        os: getNativePlatform(),
        // 新增渠道号
        invite: getChannelCode(),
        // 新增用户邀请码
        user_invite: userInvite
      })
        .then((data: any) => {
          // 保存到useUserData  中
          mutate(data)
          saveUserInfo(data)
          saveToken(data.token)
          saveUID(`${data.uid}`)
          saveCredentials('true')
          !dev &&
            getInitRouterData({
              uid: data.uid,
              token: data.token,
              setInitRouterData
            })
          return data
        })
        .catch((error: any) => {
          console.info(error)
        })
    } else {
      !dev &&
        getInitRouterData({
          uid: params.uid,
          token: params.token,
          setInitRouterData
        })
    }
  }, [])

  // 处理登录异常情况，暂时直接跳转到登录页面
  useEffect(() => {
    function handleTokenExpired() {
      onTokenExpired(() => {
        router(ROUTES.MY_LOGIN)
      })
    }
    handleTokenExpired()
    return function cleanup() {
      handleTokenExpired()
    }
  }, [])

  useEffect(() => {
    const setPropertyType = () => {
      const docElement = document.documentElement
      let safeTop = getComputedStyle(docElement).getPropertyValue('--safe-top')
      if (window?.native?.getStatusBarHeight) {
        const nativeTop = getStatusBarHeight()
        safeTop = nativeTop ? `${nativeTop}px` : safeTop
      }
      // iPhone 12安全区
      if (parseInt(safeTop, 10) > 0) {
        docElement.style.setProperty('--safe-top', `${safeTop}`)
      }
    }

    if (window?.native?.setStatusBarLight) {
      // 现在是黑色主题，默认设置为黑色，后续如果增加白色主题动态切换
      window.native.setStatusBarLight(false)
    }
    setPropertyType()
  }, [])

  return (
    <AppContext.Provider
      value={{ appData: appData, updateAppData: setAppData }}
    >
      <RootComponent initRouterData={initRouterData} />
      {/* 动态导入弹出框组件 */}
      {importedPopComponent}
    </AppContext.Provider>
  )
}

export default App
